"use strict";

var _jquery = require("jquery");
var _jquery2 = _interopRequireDefault(_jquery);
require("bootstrap");
require("slick-carousel");
require("./scss/frontend/style.scss");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
(0, _jquery2.default)(function () {
  (0, _jquery2.default)('input.radio-button').on('change', function () {
    var value = (0, _jquery2.default)('input.radio-button:checked', (0, _jquery2.default)(this).parent()).val();
    (0, _jquery2.default)('.text-input-hidden', (0, _jquery2.default)(this).parent().parent()).val(value).change().trigger('blur');
    if ((0, _jquery2.default)('.text-input-hidden', (0, _jquery2.default)(this).parent().parent()).hasClass('error')) {
      var id = (0, _jquery2.default)('.text-input-hidden', (0, _jquery2.default)(this).parent().parent()).attr('id');
      window.jQuery('form').validate().element('#' + id);
    }
  });
  (0, _jquery2.default)('input[name=radioButtonTipoDocumento]').on('change', function () {
    jQuery("#textBoxNumeroDocumento").rules('remove', 'minlength');
    jQuery("#textBoxNumeroDocumento").rules('remove', 'maxlength');
    var tipoDocumento = (0, _jquery2.default)(this).val();
    if (tipoDocumento == "DNI") jQuery("#textBoxNumeroDocumento").rules('add', {
      minlength: 8,
      maxlength: 8,
      messages: {
        minlength: "El DNI debe contener solo 8 números.",
        maxlength: "El DNI debe contener solo 8 números."
      }
    });
    if (tipoDocumento == "RUC") jQuery("#textBoxNumeroDocumento").rules('add', {
      minlength: 11,
      maxlength: 11,
      messages: {
        minlength: "El RUC debe contener solo 11 números.",
        maxlength: "El RUC debe contener solo 11 números."
      }
    });
  });
  (0, _jquery2.default)('button[data-href]').on('click', function (event) {
    var href = (0, _jquery2.default)(this).data('href');
    event.preventDefault();
    window.location.href = href;
  });
  (0, _jquery2.default)('#checkBoxMenorDeEdad').on('click', function (event) {
    (0, _jquery2.default)('.container-tutor .form-group').removeClass('error');
    if ((0, _jquery2.default)(this).is(':checked')) (0, _jquery2.default)('.container-tutor').slideDown();else (0, _jquery2.default)('.container-tutor').slideUp();
  });
  (0, _jquery2.default)('.container-tutor').hide();
});
window.checkCaptcha = function (response) {
  window.jQuery('input[name=hiddenRecaptcha]').val(response);
  window.jQuery('form').validate().element('#hiddenRecaptcha');
};